import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-birmingham-alabama.png'
import image0 from "../../images/cities/scale-model-of-locked-in:-the-birmingham-escape-game-in-birmingham-alabama.png"
import image1 from "../../images/cities/scale-model-of-birmingham-civil-rights-institute-in-birmingham-alabama.png"
import image2 from "../../images/cities/scale-model-of-mcwane-science-center-in-birmingham-alabama.png"
import image3 from "../../images/cities/scale-model-of-birmingham-zoo-in-birmingham-alabama.png"
import image4 from "../../images/cities/scale-model-of-railroad-park-foundation-in-birmingham-alabama.png"
import image5 from "../../images/cities/scale-model-of-sixteenth-street-baptist-church-in-birmingham-alabama.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Birmingham'
            state='Alabama'
            image={image}
            lat='33.5185892'
            lon='-86.8103567'
            attractions={ [{"name": "Locked In: The Birmingham Escape Game", "vicinity": "2808 7th Ave S, Birmingham", "types": ["point_of_interest", "establishment"], "lat": 33.5115948, "lng": -86.7883309}, {"name": "Birmingham Civil Rights Institute", "vicinity": "520 16th St N, Birmingham", "types": ["museum", "point_of_interest", "establishment"], "lat": 33.5160919, "lng": -86.81452109999998}, {"name": "McWane Science Center", "vicinity": "3117, 200 19th St N, Birmingham", "types": ["museum", "movie_theater", "point_of_interest", "establishment"], "lat": 33.5149931, "lng": -86.80818750000003}, {"name": "Birmingham Zoo", "vicinity": "2630 Cahaba Rd, Birmingham", "types": ["zoo", "aquarium", "point_of_interest", "establishment"], "lat": 33.4860087, "lng": -86.77954139999997}, {"name": "Railroad Park Foundation", "vicinity": "1600 1st Ave S, Birmingham", "types": ["park", "point_of_interest", "establishment"], "lat": 33.5098585, "lng": -86.80840360000002}, {"name": "Sixteenth Street Baptist Church", "vicinity": "1530 6th Ave N, Birmingham", "types": ["church", "place_of_worship", "point_of_interest", "establishment"], "lat": 33.516743, "lng": -86.81497350000001}] }
            attractionImages={ {"Locked In: The Birmingham Escape Game":image0,"Birmingham Civil Rights Institute":image1,"McWane Science Center":image2,"Birmingham Zoo":image3,"Railroad Park Foundation":image4,"Sixteenth Street Baptist Church":image5,} }
       />);
  }
}